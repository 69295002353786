;(function () {
  // window.Vue.directive('click-outside', {
  //   bind: function (el, binding, vnode) {
  //     el.clickOutsideEvent = function (event) {
  //       // here I check that click was outside the el and his children
  //       if (
  //         !(
  //           el == event.target ||
  //           el.contains(event.target) ||
  //           event.target.classList.contains('ignore-v-click-outside')
  //         )
  //       ) {
  //         // and if it did, call method provided in attribute value
  //         vnode.context[binding.expression](event)
  //       }
  //     }
  //     document.body.addEventListener('click', el.clickOutsideEvent)
  //   },
  //   unbind: function (el) {
  //     document.body.removeEventListener('click', el.clickOutsideEvent)
  //   }
  // })

  var app = new window.Vue({
    el: '#cart',
    store: window.store,
    data: {
      cartOpen: false
    },
    computed: {
      ...window.Vuex.mapGetters({
        items: 'cartItems',
        total: 'cartTotalPrice',
        count: 'cartItemCount'
      }),
      isMobile: function () {
        return window.matchMedia('(max-width: 400px)').matches
      }
    },
    watch: {
      // whenever question changes, this function will run
      count: function (newCount, oldCount) {
        if (newCount > oldCount && window.location.pathname !== '/cart') {
          this.cartOpen = true
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          })
          setTimeout(
            function () {
              this.cartOpen = false
            }.bind(this),
            2000
          )
        }
      }
    },
    methods: {
      toggleCart: function (e) {
        this.cartOpen = !this.cartOpen
        e.stopPropagation()
        e.preventDefault()
      },
      openCart: function (e) {
        if (!this.cartOpen) {
          this.cartOpen = true
        }
      },
      closeCart: function (e) {
        if (this.cartOpen) {
          this.cartOpen = false
        }
      }
    }
  })
})()
//
